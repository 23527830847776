@media(min-width: 768px) {
    .hydropod-lead {
        font-size: 32px;
    }
    .hydropod-logo {
        width: 400px;
    }
    .data-table {
        width: 300px;
        display: inline-table;
    }
    .select-container > select {
        width: 120px;
    }
    .select-container {
        width: 250px;
        margin-left: 30px;
    }
    #watering-submit {
        width: 200px;
    }
    .buttons-inline {
        width: 200px;
        margin-right: 10px;
        float: left;
    }
    .model {
        height: 200px;
        width: 200px;
    }
}
@media(max-width: 767px) {
    .hydropod-lead {
        font-size: 22px;
    }
    .visible {
        display: inline-block;
    }
    .hydropod-lead {
        font-size: 24px;
    }
    .hydropod-logo {
        width: 300px;
    }
    .data-table {
        width: 100%;
    }
    .select-container > select {
        width: 50%;
    }
    .select-container {
        width: 100%;
        margin-top: 10px;
    }
    #watering-submit {
        width: 100%;
    }
    .buttons-inline {
        width: 100%;
        margin-top: 10px;
    }
    .model {
        height: 100px;
        width: 100px;
    }
}
.hydropod-lead {
    color: gray;
}
.white {
    color: white;
}
.header {
    margin-top: 20px;
}
.credentials-form {
    width: 300px;
    float: none;
    margin: 0 auto;
    padding: 20px 30px;
    border: 1px solid darkgray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    margin-bottom: 60px;
}
.control-center {
    width: 100%;
    height: 610px;
    float: none;
    margin: 0 auto;
    padding: 20px 30px;
    border: 1px solid darkgray;
    border-top: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    margin-bottom: 60px;
    position: relative;
}
.buttons-inline > form {
    display: inline-block;
    margin-right: 20px;
}
@media(min-width: 1920px) {
    .image-container {
        background-image: url(./img/background.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }
}
@media(min-height: 900px) {
    .image-container {
        background-image: url(./img/background.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }
}
@media(max-height: 900px) {
    .image-container {
        background: url(./img/background.jpg) 0px 0px no-repeat;
    }
}
.image-container {
    min-height: 80vh;
}
.select-header > p {
    display: inline-block;
}
.select-container {
    display: inline-flex;
}
.select-container {
    margin-top: 30px;
}
#devices {
    float: left;
    margin-right: 10px;
}
#timeframe {
    float: right;
    margin-left: 10px;
}
#timeframe-header {
    float: right;
    display: inline-block;
}
.vertical-center{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
body, html{
    height: 100%;
    font-family: "Avenir Next", "Helvetica", "sans-serif";
}
.navbar-header {
    float: left;
    display: inline-block;
}
.navbar-links {
    display: inline-block;
    float: right;
    margin-top: 10px;
    list-style: none;
}
.navbar-link {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    text-align: center;
}
#chartContainer {
    height: 420px;
    width: 100%;
    margin-bottom: 10px;
}
.data-info {
    display: inline-block;
    width: 100%;
}
#watering-submit {
    margin-top: 10px;
    float: right;
}
.watering-level-selector {
    border: 1px solid lightgray;
    padding: 10px;
}
.copyright {
    font-family: "Avenir Next", "Helvetica", "sans-serif";
    margin-top: 20px;
}
.model {
    display: inline-block;
    background-color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px;
}
.model:hover {
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}
.content {
    min-height: 80vh;
}
.teaser-fullscreen {
    min-height: inherit;
}
.hydropod-content {
    padding-bottom: 0;
}
.company-info {
    margin-top: -20%;
}
.company-email {
    color: black;
}
body {
    background-color: #2B2B2B;
}
.footer {
    color: #777;
    padding-top: 2%;
    height: 100px;
}
.jumbotron {
    background-color: white;
    margin-bottom: 0;
    color: black;
    border-bottom: #2B2B2B;
    border-radius: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}